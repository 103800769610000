interface ICustomer {
  id: number;
  name: string;
  email: string;
  number: string;
  createdAt: string;
}

const customers: Array<ICustomer> = [
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Melody Macy",
    email: "melody@altbox.com",
    number: "+151054XXXXX",
    createdAt: "01 Dec 2020, 10:12 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Max Smith",
    email: "max@kt.com",
    number: "+151054XXXXX",
    createdAt: "12 Nov 2020, 2:01 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Sean Bean",
    email: "sean@dellito.com",
    number: "+151054XXXXX",
    createdAt: "21 Oct 2020, 5:54 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Brian Cox",
    email: "brian@exchange.com",
    number: "+151054XXXXX",
    createdAt: "19 Oct 2020, 7:32 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Mikaela Collins",
    email: "mikaela@pexcom.com",
    number: "+151054XXXXX",
    createdAt: "23 Sep 2020, 12:37 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Francis Mitcham",
    email: "f.mitcham@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "11 Sep 2020, 3:15 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Olivia Wild",
    email: "olivia@corpmail.com",
    number: "+151054XXXXX",
    createdAt: "03 Sep 2020, 1:08 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Neil Owen",
    email: "owen.neil@gmail.com",
    number: "+151054XXXXX",
    createdAt: "01 Sep 2020, 4:58 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Dan Wilson",
    email: "dam@consilting.com",
    number: "+151054XXXXX",
    createdAt: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Bold",
    email: "emma@intenso.com",
    number: "+151054XXXXX",
    createdAt: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    number: "+151054XXXXX",
    createdAt: "11 Aug 2020, 5:13 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Melody Macy",
    email: "melody@altbox.com",
    number: "+151054XXXXX",
    createdAt: "01 Dec 2020, 10:12 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Max Smith",
    email: "max@kt.com",
    number: "+151054XXXXX",
    createdAt: "12 Nov 2020, 2:01 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Sean Bean",
    email: "sean@dellito.com",
    number: "+151054XXXXX",
    createdAt: "21 Oct 2020, 5:54 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Brian Cox",
    email: "brian@exchange.com",
    number: "+151054XXXXX",
    createdAt: "19 Oct 2020, 7:32 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Mikaela Collins",
    email: "mikaela@pexcom.com",
    number: "+151054XXXXX",
    createdAt: "23 Sep 2020, 12:37 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Francis Mitcham",
    email: "f.mitcham@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "11 Sep 2020, 3:15 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Olivia Wild",
    email: "olivia@corpmail.com",
    number: "+151054XXXXX",
    createdAt: "03 Sep 2020, 1:08 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Neil Owen",
    email: "owen.neil@gmail.com",
    number: "+151054XXXXX",
    createdAt: "01 Sep 2020, 4:58 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Dan Wilson",
    email: "dam@consilting.com",
    number: "+151054XXXXX",
    createdAt: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Bold",
    email: "emma@intenso.com",
    number: "+151054XXXXX",
    createdAt: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    number: "+151054XXXXX",
    createdAt: "11 Aug 2020, 5:13 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Melody Macy",
    email: "melody@altbox.com",
    number: "+151054XXXXX",
    createdAt: "01 Dec 2020, 10:12 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Max Smith",
    email: "max@kt.com",
    number: "+151054XXXXX",
    createdAt: "12 Nov 2020, 2:01 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Sean Bean",
    email: "sean@dellito.com",
    number: "+151054XXXXX",
    createdAt: "21 Oct 2020, 5:54 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Brian Cox",
    email: "brian@exchange.com",
    number: "+151054XXXXX",
    createdAt: "19 Oct 2020, 7:32 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Mikaela Collins",
    email: "mikaela@pexcom.com",
    number: "+151054XXXXX",
    createdAt: "23 Sep 2020, 12:37 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Francis Mitcham",
    email: "f.mitcham@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "11 Sep 2020, 3:15 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Olivia Wild",
    email: "olivia@corpmail.com",
    number: "+151054XXXXX",
    createdAt: "03 Sep 2020, 1:08 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Neil Owen",
    email: "owen.neil@gmail.com",
    number: "+151054XXXXX",
    createdAt: "01 Sep 2020, 4:58 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Dan Wilson",
    email: "dam@consilting.com",
    number: "+151054XXXXX",
    createdAt: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Bold",
    email: "emma@intenso.com",
    number: "+151054XXXXX",
    createdAt: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    number: "+151054XXXXX",
    createdAt: "11 Aug 2020, 5:13 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Melody Macy",
    email: "melody@altbox.com",
    number: "+151054XXXXX",
    createdAt: "01 Dec 2020, 10:12 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Max Smith",
    email: "max@kt.com",
    number: "+151054XXXXX",
    createdAt: "12 Nov 2020, 2:01 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Sean Bean",
    email: "sean@dellito.com",
    number: "+151054XXXXX",
    createdAt: "21 Oct 2020, 5:54 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Brian Cox",
    email: "brian@exchange.com",
    number: "+151054XXXXX",
    createdAt: "19 Oct 2020, 7:32 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Mikaela Collins",
    email: "mikaela@pexcom.com",
    number: "+151054XXXXX",
    createdAt: "23 Sep 2020, 12:37 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Francis Mitcham",
    email: "f.mitcham@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "11 Sep 2020, 3:15 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Olivia Wild",
    email: "olivia@corpmail.com",
    number: "+151054XXXXX",
    createdAt: "03 Sep 2020, 1:08 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Neil Owen",
    email: "owen.neil@gmail.com",
    number: "+151054XXXXX",
    createdAt: "01 Sep 2020, 4:58 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Dan Wilson",
    email: "dam@consilting.com",
    number: "+151054XXXXX",
    createdAt: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Bold",
    email: "emma@intenso.com",
    number: "+151054XXXXX",
    createdAt: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    number: "+151054XXXXX",
    createdAt: "11 Aug 2020, 5:13 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Melody Macy",
    email: "melody@altbox.com",
    number: "+151054XXXXX",
    createdAt: "01 Dec 2020, 10:12 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Max Smith",
    email: "max@kt.com",
    number: "+151054XXXXX",
    createdAt: "12 Nov 2020, 2:01 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Sean Bean",
    email: "sean@dellito.com",
    number: "+151054XXXXX",
    createdAt: "21 Oct 2020, 5:54 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Brian Cox",
    email: "brian@exchange.com",
    number: "+151054XXXXX",
    createdAt: "19 Oct 2020, 7:32 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Mikaela Collins",
    email: "mikaela@pexcom.com",
    number: "+151054XXXXX",
    createdAt: "23 Sep 2020, 12:37 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Francis Mitcham",
    email: "f.mitcham@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "11 Sep 2020, 3:15 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Olivia Wild",
    email: "olivia@corpmail.com",
    number: "+151054XXXXX",
    createdAt: "03 Sep 2020, 1:08 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Neil Owen",
    email: "owen.neil@gmail.com",
    number: "+151054XXXXX",
    createdAt: "01 Sep 2020, 4:58 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Dan Wilson",
    email: "dam@consilting.com",
    number: "+151054XXXXX",
    createdAt: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Bold",
    email: "emma@intenso.com",
    number: "+151054XXXXX",
    createdAt: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    number: "+151054XXXXX",
    createdAt: "11 Aug 2020, 5:13 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Melody Macy",
    email: "melody@altbox.com",
    number: "+151054XXXXX",
    createdAt: "01 Dec 2020, 10:12 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Max Smith",
    email: "max@kt.com",
    number: "+151054XXXXX",
    createdAt: "12 Nov 2020, 2:01 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Sean Bean",
    email: "sean@dellito.com",
    number: "+151054XXXXX",
    createdAt: "21 Oct 2020, 5:54 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Brian Cox",
    email: "brian@exchange.com",
    number: "+151054XXXXX",
    createdAt: "19 Oct 2020, 7:32 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Mikaela Collins",
    email: "mikaela@pexcom.com",
    number: "+151054XXXXX",
    createdAt: "23 Sep 2020, 12:37 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Francis Mitcham",
    email: "f.mitcham@kpmg.com.au",
    number: "+151054XXXXX",
    createdAt: "11 Sep 2020, 3:15 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Olivia Wild",
    email: "olivia@corpmail.com",
    number: "+151054XXXXX",
    createdAt: "03 Sep 2020, 1:08 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Neil Owen",
    email: "owen.neil@gmail.com",
    number: "+151054XXXXX",
    createdAt: "01 Sep 2020, 4:58 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Dan Wilson",
    email: "dam@consilting.com",
    number: "+151054XXXXX",
    createdAt: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Emma Bold",
    email: "emma@intenso.com",
    number: "+151054XXXXX",
    createdAt: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    number: "+151054XXXXX",
    createdAt: "11 Aug 2020, 5:13 pm",
  },
];

export { ICustomer };

export default customers;
